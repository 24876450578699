<template>
    <div class="search-wrapper">
        <div class="title-bar search-title-bar">
          <h1 class="search-title">Users</h1>
          <div class="search-input-wrapper">
            <v-autocomplete
              class="search-input"
              :placeholder="'Search'"
              :no-data-text="'No users found'"
              :color="colors.PRIMARY"
              :items="shownUserList"
              :item-text="'name'"
              :item-value="'email'"
              :loading="isLoadingOptions"
              v-model="selectedUser"
              v-on:change="goToUser"
            >
              <template v-slot:append-outer>
                <v-slide-x-reverse-transition mode="out-in">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :color="colors.PRIMARY"
                        v-on="on"
                        v-on:click="toggleDisabledUsers"
                        v-text="viewDisabledUsers ? 'mdi-account-off' : 'mdi-account'"
                      ></v-icon>
                    </template>
                    <span v-if="!viewDisabledUsers">View Disabled Users</span>
                    <span v-else>Hide Disabled Users</span>
                  </v-tooltip>
                </v-slide-x-reverse-transition>
              </template>
            </v-autocomplete>
          </div>
        </div>
        <div class="user-list">
          <v-list class="transparent-element">
            <v-list-item
              class="padded-tile"
              v-for="user in shownUserList"
              :key="user.email"
              @click="goToUser(user.email)"
            >
              <v-list-item-title v-text="user.name"></v-list-item-title>
              <v-list-item-subtitle v-text="user.email"></v-list-item-subtitle>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-if="!user.isEnabled" color="grey lighten-1" v-on="on">mdi-account-off</v-icon>
                </template>
                <span>This User is Disabled</span>
              </v-tooltip>
              <v-icon v-if="user.isEnabled" color="grey lighten-1">mdi-account</v-icon>
            </v-list-item>
          </v-list>
        </div>
      </div>
</template>

<script>
import colors from "@/colors";
import userDataAccess from '../../../dataAccess/userDataAccess';

export default {
    name: "usersearch",
    data() {
    return {
      colors,
      isLoadingOptions: true,
      enabledUserList: [],
      entireUserList: [],
      shownUserList: [],
      selectedUser: "",
      viewDisabledUsers: false,
      on: false,
      userDAO: new userDataAccess()
    };
  },
  methods: {
    goToUser(email) {
      this.$router.push("/account?user=" + email);
    },
    toggleDisabledUsers() {
      if (this.viewDisabledUsers) {
        this.shownUserList = this.enabledUserList;
      } else {
        this.shownUserList = this.entireUserList;
      }
      this.viewDisabledUsers = !this.viewDisabledUsers;
    }
  },
  mounted: async function() {
    let response = "";
    try {
      response = await this.userDAO.getUsers();
    } catch (err) {
      console.log(err);
      return;
    }
    this.entireUserList = response;
    this.entireUserList.forEach(element => {
      if (element.isEnabled) {
        this.enabledUserList.push(element);
      }
    });
    this.shownUserList = this.enabledUserList;
    this.isLoadingOptions = false;
  }
}
</script>

<style scoped>
.search-wrapper {
  margin: 20px;
  box-shadow: 0px 1px 5px #aaaaaa;
  max-width: 800px;
  background-color: #fefefe;
}

.search-input-wrapper {
  margin-right: 25px;
  margin-left: 25px;
  width: 100%;
}

.search-title-bar {
  display: flex;
  flex-direction: row;
  align-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.search-title {
  margin-top: 12px;
}

.transparent-element {
  background-color: transparent !important;
}

.padded-tile {
  padding: 2px 15px;
}
</style>