<template>
  <div class="manageusers">
    <div class="title-bar">
      <h1>Manage Users</h1>
    </div>
    <div class="search-container">
      <UserSearch/>
    </div>
  </div>
</template>

<script>
import UserSearch from "./components/UserSearch.vue";

export default {
  name: "manageusers",
  components: {
    UserSearch
  }
};
</script>

<style scoped>
.search-container {
  text-align: left;
}
</style>